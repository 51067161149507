/**
 * This callback type is called `requestCallback` and is displayed as a global symbol.
 *
 * @callback iterateeCallback
 * @param {*} Value
 * @param {string} Key
 * @param {object} Original object
 */
/**
 * Iterates over object values
 * @param {Object} collection
 * @param {iterateeCallback} iteratee
 * @return {Object} iterated object
 */
module.exports.mapValues = function(collection, iteratee) {
  if (!collection) {
    return {};
  }

  return Object.keys(collection).reduce((acc, key) => {
    acc[key] = iteratee(collection[key], key, collection);
    return acc;
  }, {});
};

module.exports.filterValues = function(collection, iteratee) {
  if (!collection) {
    return {};
  }

  return Object.keys(collection).reduce((acc, key) => {
    const keep = iteratee(collection[key], key, collection);

    if (keep) {
      acc[key] = collection[key];
    }

    return acc;
  }, {});
};
