const debounce = require('./debounce');
const throttle = require('./throttle');

const createEvent = (event, context, startTime) => ({
  dt: Date.now() - startTime,
  f: event,
  context,
});

const createBatch = (events, startTime) => ({
  dt: Date.now() - startTime,
  e: events,
  g: {},
});

const optimizeBatch = batch => {
  const fieldCounts = {};
  const batchLen = batch.e.length;

  let events = batch.e.map(event => {
    const fields = Object.keys(event.f).map(field => {
      const value = event.f[field];
      const key = `${field}|${value}`;
      fieldCounts[key] = fieldCounts[key] || 0;
      fieldCounts[key]++;
      return [field, value, key];
    });

    return {
      ...event,
      f: fields,
    };
  });

  const globalFields = {};
  events = events.map(event => {
    const fields = event.f.reduce((res, [field, value, key]) => {
      if (fieldCounts[key] === batchLen) {
        globalFields[field] = value;
      } else {
        res[field] = value;
      }

      return res;
    }, {});

    return {
      ...event,
      f: fields,
    };
  });

  return {
    ...batch,
    e: events,
    g: globalFields,
  };
};

class BatchQueue {
  constructor() {
    this._initilized = false;
  }

  _reset() {
    this._startTime = Date.now();
    this._resolve = null;
    this._promise = new Promise(resolve => (this._resolve = resolve));
  }

  init({ delayMs, maxBatchSize, useThrottle, optimizeBatch }, flushHandler) {
    if (this._initilized) {
      return;
    }

    this._maxBatchSize = maxBatchSize;
    this._optimizeBatch = optimizeBatch;
    this._queue = [];
    this._flushHandler = flushHandler;
    this._flushDebounced = useThrottle
      ? throttle(() => this.flush(), delayMs)
      : debounce(() => this.flush(), delayMs);
    this._initilized = true;

    this._reset();
  }

  flush() {
    if (!this._queue.length) {
      return Promise.resolve();
    }

    const events = this._queue.splice(0, this._queue.length);
    const resolve = this._resolve;
    const startTime = this._startTime;

    this._reset();

    let batch = createBatch(events, startTime);

    if (this._optimizeBatch) {
      batch = optimizeBatch(batch);
    }

    return this._flushHandler(batch).then(resolve);
  }

  feed(event, context) {
    this._queue.push(createEvent(event, context, this._startTime));

    if (this._queue.length === this._maxBatchSize) {
      return this.flush();
    }

    this._flushDebounced();

    return this._promise;
  }
}

module.exports = BatchQueue;
