'use strict'; // eslint-disable-line strict
const assert = require('./assert');
const BiLogger = require('./bi-logger');
const biLoggerManager = require('./bi-logger-manager');
const BatchQueue = require('./utils/batch-queue');

class BiLoggerFactory {
  constructor() {
    this._publishers = [];
    this._validators = [];
    this._defaults = {};
    this._nonEssentialDefaults = {};
    this._events = {};
    this._isMuted = false;
    this._eventTransformer = null;
    this._payloadTransformer = null;
    this._consentPolicyGetter = null;
    this._maxBatchSize = null;
    this._batchQueue = null;
  }

  addPublisher(publisher) {
    assert.defined(publisher, 'Publisher must be provided');
    assert.ok(typeof publisher === 'function', 'Expected a publisher function');
    this._publishers.push(publisher);
    return this;
  }

  addValidator(validator) {
    assert.defined(validator, 'Validator must be provided');
    assert.ok(
      typeof validator === 'object' && validator,
      'Expected a validator object',
    );
    assert.ok(
      validator.execute && validator.match,
      'Provided validator does not match the interface',
    );
    this._validators.push(validator);
    return this;
  }

  setDefaults(defaults) {
    assert.defined(defaults, 'Defaults must be provided');
    assert.object(defaults, 'Defaults must be an object');
    this._defaults = defaults;
    return this;
  }

  updateDefaults(defaults) {
    assert.defined(defaults, 'Defaults must be provided');
    assert.object(defaults, 'Defaults must be an object');
    Object.assign(this._defaults, defaults);
    return this;
  }

  updateNonEssentialDefaults(defaults) {
    assert.defined(defaults, 'Non-essential Defaults must be provided');
    assert.object(defaults, 'Non-essential Defaults must be an object');
    Object.assign(this._nonEssentialDefaults, defaults);
    return this;
  }

  setEvents(events) {
    assert.defined(events, 'Events must be provided');
    assert.object(events, 'Events must be an object');
    this._events = events;
    return this;
  }

  setDefaultValueTimeout(defaultValueTimeout) {
    assert.defined(
      defaultValueTimeout,
      'Default Value Timeout must be provided',
    );
    this._defaultValueTimeout = defaultValueTimeout;
    return this;
  }

  setDefaultContinueOnFail(defaultContinueOnFail) {
    assert.defined(
      defaultContinueOnFail,
      'Default Continue On Fail must be provided',
    );
    this._defaultContinueOnFail = defaultContinueOnFail;
    return this;
  }

  setPublisherFailHandler(onPublisherFailHandler) {
    assert.defined(
      onPublisherFailHandler,
      'Publisher Fail Handler must be provided',
    );
    this._onPublisherFailHandler = onPublisherFailHandler;
    return this;
  }

  setMuted(isMuted) {
    assert.defined(isMuted, 'Is Muted must be provided');
    assert.boolean(isMuted, 'Is Muted must be a boolean');
    this._isMuted = isMuted;
    return this;
  }

  setMaxBatchSize(maxBatchSize) {
    assert.defined(maxBatchSize, 'Max Batch Size must be provided');
    assert.number(maxBatchSize, 'Max Batch Size must be a number');
    assert.ok(maxBatchSize > 0, 'Max Batch Size must be higher than 0');
    this._maxBatchSize = maxBatchSize;
    return this;
  }

  setGlobalBatchQueue(batchQueue) {
    assert.defined(batchQueue, 'Global Batch Queue must be provided');
    assert.ok(
      batchQueue instanceof BatchQueue,
      'Global Batch Queue must be an instance of BatchQueue',
    );
    this._globalBatchQueue = batchQueue;
    return this;
  }

  withEventTransformer(transformer) {
    assert.defined(transformer, 'Event Transformer must be provided');
    assert.func(transformer, 'Event Transformer must be a function');
    this._eventTransformer = transformer;
    return this;
  }

  withPayloadTransformer(transformer) {
    assert.defined(transformer, 'Payload Transformer must be provided');
    assert.func(transformer, 'Payload Transformer must be a function');
    this._payloadTransformer = transformer;
    return this;
  }

  withConsentPolicyGetter(getter) {
    assert.defined(getter, 'Consent Policy Getter must be provided');
    assert.func(getter, 'Consent Policy Getter must be a function');
    this._consentPolicyGetter = getter;
    return this;
  }

  logger(context) {
    const logger = new BiLogger(
      {
        publishers: this._publishers,
        validators: this._validators,
        defaults: this._defaults,
        events: this._events,
        defaultValueTimeout: this._defaultValueTimeout,
        defaultContinueOnFail: this._defaultContinueOnFail,
        onPublisherFailHandler: this._onPublisherFailHandler,
        isMuted: () => this._isMuted,
        eventTransformer: this._eventTransformer,
        payloadTransformer: this._payloadTransformer,
        consentPolicyGetter: this._consentPolicyGetter,
        nonEssentialDefaults: this._nonEssentialDefaults,
        maxBatchSize: this._maxBatchSize,
        globalBatchQueue: this._globalBatchQueue,
      },
      context,
    );

    biLoggerManager.manager.notifyLoggerCreated(logger);

    return logger;
  }
}

module.exports = BiLoggerFactory;
