'use strict'
const panelDefs = require('../panels/panelDefs')

module.exports = t => ({
  routerActions: {
    default: [
      {
        title: t('TreeView_QuickActions_Change_Router'),
        event: 'updateRouterClicked',
        icon: 'edit_icon'
      },
      {
        title: t('TreeView_QuickActions_Add_Page_To_Router'),
        event: 'addPageToRouterClicked',
        icon: 'router_icon',
        type: 'add_page'
      },
      {
        title: t('TreeView_QuickActions_Delete_Router'),
        event: 'removeRouterClicked',
        icon: 'delete_icon'
      }
    ]
  },
  pageActions: {
    default: [
      'Pages_Actions_Page_Rename',
      {
        title: t('Page_Actions_Page_Remove'),
        event: 'disconnectPageFromRouterClicked',
        icon: 'unlink_icon'
      },
      {
        title: t('Page_Actions_Page_Delete'),
        event: 'deleteDynamicPageClicked',
        icon: 'delete_icon'
      }
    ]
  },
  pageSettings: {
    default: [
      {
        type: 'page_info',
        title: t('PAGES_INFO_TAB_TITLE'),
        url: panelDefs.pageInfoPanel.url,
        helpId: 'a3021dde-c68d-4858-845e-f28947506761'
      },
      {
        type: 'layout',
        title: t('Pages_Layouts_Tab_Title'),
        helpId: '3ea9a842-9dad-46ae-aa5d-d1ef049807f1'
      },
      {
        type: 'permissions',
        title: t('Pages_Permissions_Tab_Title'),
        helpId: '5275e972-74ff-494d-99ef-fc493e198dc4'
      },
      {
        type: 'seo',
        title: t('PAGES_SEO_TAB_TITLE'),
        url: panelDefs.pageSeoPanel.url,
        helpId: 'd0fab741-2e34-4441-a9d1-379d5180badf'
      }
    ]
  }
})
