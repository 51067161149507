'use strict'; // eslint-disable-line strict
const assert = require('./assert');
const { mapValues, filterValues } = require('./utils/collections');
const promise = require('./utils/promise');
const log = require('./utils/log');
const BatchQueue = require('./utils/batch-queue');
const {
  shouldMuteByCategory,
  shouldMuteNonEssentials,
  getPolicy,
} = require('./consent-policy');

class BiLogger {
  // TODO: validate args
  constructor(options, context) {
    this._publishers = options.publishers;
    this._validators = options.validators || [];
    this._defaults = options.defaults;
    this._ownDefaults = {};
    this._events = options.events || {};
    this._context = context || {};
    this._defaultValueTimeout = options.defaultValueTimeout || 5000;
    this._defaultContinueOnFail = options.defaultContinueOnFail || false;
    this._onPublisherFailHandler =
      options.onPublisherFailHandler || BiLogger._defaultPublisherFailHandler;
    this._isMuted = options.isMuted || (() => false);
    this._eventTransformer = options.eventTransformer || (event => event);
    this._payloadTransformer =
      options.payloadTransformer || (payload => payload);
    this._consentPolicyGetter = options.consentPolicyGetter || (() => null);
    this._nonEssentialDefaults = options.nonEssentialDefaults || {};
    this._maxBatchSize = options.maxBatchSize || 100;
    this._globalBatchQueue = options.globalBatchQueue;
  }

  report(data) {
    assert.defined(data, 'Data must be provided');
    assert.object(data, 'Data must be an object');

    const { src, evid, params, ...context } = data;

    return this.log({ src, evid, ...params }, context);
  }

  log(eventOrKey, eventOrContextOrUndefined, contextOrUndefined) {
    assert.defined(eventOrKey, 'Event object or event key must be provided.');

    const { event, context } = this._extractEventAndContext(
      eventOrKey,
      eventOrContextOrUndefined,
      contextOrUndefined,
    );
    const policy = getPolicy(this._consentPolicyGetter);
    const fullContext = { ...this._context, ...context };

    if (this._isMuted() || shouldMuteByCategory(policy, fullContext.category)) {
      return Promise.resolve();
    }

    if (fullContext.useBatch) {
      const queue = this._initQueue(fullContext, policy);

      const transformAndQueue = _event => {
        const transformedEvent = this._eventTransformer(_event, fullContext);

        return queue.feed(transformedEvent, fullContext);
      };

      if (this._globalBatchQueue) {
        return this._getDefaults(this._defaults).then(defaults => {
          const fullEvent = {
            ...defaults,
            ...this._getDynamicNonEssentialDefaults(policy),
            ...this._getStaticNonEssentialDefaults(policy),
            ...event,
            ...this._getPolicyFields(policy, fullContext.category),
          };

          return transformAndQueue(fullEvent);
        });
      } else {
        const fullEvent = {
          ...this._getDynamicDefaults(this._defaults),
          ...this._getDynamicNonEssentialDefaults(policy),
          ...event,
          ...this._getPolicyFields(policy, fullContext.category),
        };

        return transformAndQueue(fullEvent);
      }
    }

    return this._getDefaults(this._defaults).then(defaults => {
      const fullEvent = Object.assign(
        defaults,
        this._getDynamicNonEssentialDefaults(policy),
        this._getStaticNonEssentialDefaults(policy),
        event,
        this._getPolicyFields(policy, fullContext.category),
      );

      const validatorsResult =
        this._validators.length === 0
          ? true
          : this._validators.some(
              validator =>
                validator.match(fullEvent) &&
                (validator.execute(fullEvent) || true),
            );

      if (!validatorsResult) {
        throw new Error(
          `No validator accepted the event. Source: ${
            fullEvent.src
          } Evid: ${fullEvent.evid || fullEvent.evtId}`,
        );
      }

      let transformedEvent = this._eventTransformer(fullEvent, fullContext);
      transformedEvent = this._payloadTransformer(
        transformedEvent,
        fullContext,
      );

      return this._send(transformedEvent, fullContext);
    });
  }

  flush() {
    if (!this._queue) {
      return Promise.resolve();
    }
    return this._queue.flush();
  }

  updateDefaults(defaults) {
    assert.defined(defaults, 'Defaults must be provided');
    assert.object(defaults, 'Defaults must be an object');
    Object.assign(this._ownDefaults, defaults);
    return this;
  }

  _send(payload, context = {}) {
    return Promise.all(
      this._publishers.map(publisher => {
        const cloned = { ...payload };

        return Promise.resolve()
          .then(() => publisher(cloned, context))
          .catch(error =>
            this._onPublisherFailHandler(error, {
              publisherName: publisher.name,
              payload,
            }),
          );
      }),
    ).then(() => undefined);
  }

  _extractEventAndContext(
    eventOrKey,
    eventOrContextOrUndefined,
    contextOrUndefined,
  ) {
    let event;
    let context = {};

    if (typeof eventOrKey !== 'string') {
      event = eventOrKey;
      context = eventOrContextOrUndefined || context;
    } else {
      event = this._events[eventOrKey];

      if (!event) {
        throw new assert.AssertionError(
          `Event with key '${eventOrKey}' not found in event map.`,
        );
      }

      if (eventOrContextOrUndefined) {
        event = { ...event, ...eventOrContextOrUndefined };
        context = contextOrUndefined || context;
      }
    }

    return { event, context };
  }

  _initQueue(context, policy) {
    if (this._queue) {
      return this._queue;
    }

    this._queue = this._globalBatchQueue || new BatchQueue();

    const onFlush = batch => {
      // if queue is global don't define global properties
      if (!this._globalBatchQueue) {
        batch.g = Object.assign(
          this._getStaticDefaults(this._defaults),
          this._getStaticNonEssentialDefaults(policy),
        );
      }

      const transformedPayload = this._payloadTransformer(batch, context);

      return this._send(transformedPayload, context);
    };

    this._queue.init(
      {
        delayMs: context.useBatch === true ? 300 : context.useBatch,
        maxBatchSize: this._maxBatchSize,
        useThrottle: !!this._globalBatchQueue,
        optimizeBatch: !!this._globalBatchQueue,
      },
      onFlush,
    );

    return this._queue;
  }

  _handleDefaultsError(err) {
    if (this._defaultContinueOnFail) {
      log.error(err);
      return null;
    }
    return Promise.reject(err);
  }

  _getDynamicNonEssentialDefaults(policy) {
    if (!shouldMuteNonEssentials(policy)) {
      return this._getDynamicDefaults(this._nonEssentialDefaults);
    }
  }

  _getStaticNonEssentialDefaults(policy) {
    if (!shouldMuteNonEssentials(policy)) {
      return this._getStaticDefaults(this._nonEssentialDefaults);
    }
  }

  _withOwnDefaults(defaults) {
    return Object.assign({}, defaults, this._ownDefaults);
  }

  _getDynamicDefaults(defaults) {
    defaults = this._withOwnDefaults(defaults);
    const dynamicDefaults = filterValues(
      defaults,
      v => typeof v === 'function',
    );
    return mapValues(dynamicDefaults, v => v());
  }

  _getStaticDefaults(defaults) {
    defaults = this._withOwnDefaults(defaults);
    const staticDefaults = filterValues(defaults, v => typeof v !== 'function');
    return staticDefaults;
  }

  _getDefaults(defaults) {
    defaults = this._withOwnDefaults(defaults);
    if (!defaults) {
      return Promise.resolve({});
    }

    const promises = mapValues(defaults, (value, key) => {
      if (typeof value === 'function') {
        try {
          value = value();
        } catch (err) {
          return this._handleDefaultsError(err);
        }
      }

      if (value && typeof value.then === 'function') {
        return promise
          .timedPromise(value, {
            message: `Cannot get default value '${key} for BI Event'`,
            timeout: this._defaultValueTimeout,
          })
          .catch(err => this._handleDefaultsError(err));
      }

      return value;
    });

    return promise.allAsObject(promises);
  }

  _encodePolicyValue(policy, key) {
    if (!policy) {
      return 1;
    }

    if (typeof policy[key] === 'boolean') {
      return policy[key] ? 1 : 0;
    }

    return policy[key];
  }

  _getPolicyFields(policy, category) {
    return {
      _isca: this._encodePolicyValue(policy, 'analytics'),
      _iscf: this._encodePolicyValue(policy, 'functional'),
      _ispd: policy.__default ? 1 : 0,
      _ise: category === 'essential' ? 1 : 0,
    };
  }

  static _defaultPublisherFailHandler(error, { publisherName }) {
    return publisherName; // do nothing
  }
}

module.exports = BiLogger;
