'use strict'
const panelDefs = require('../../../panels/panelDefs')

module.exports = (routerRef, writableRouters = true) => ({
  url: panelDefs.updatePrefixPanel.url,
  width: '744',
  height: writableRouters ? '522' : '600',
  shouldHideHeader: true,
  initialData: {
    routerRef
  }
})
