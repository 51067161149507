'use strict'

module.exports = {
  PANEL_TYPES: {
    REMOVE: 'deleteDynamicPage',
    DISCONNECT: 'disconnectDynamicPage'
  },
  PANEL_TEXTS: {
    deleteDynamicPage: {
      DESCRIPTION: 'WixCode_Delete_Router_Text',
      CANCEL_BUTTON: 'WixCode_Delete_Router_CancelButton',
      CONFIRM_BUTTON: 'WixCode_Delete_Router_DeleteButton',
      TITLE: 'WixCode_Delete_Router_Title'
    },
    disconnectDynamicPage: {
      DESCRIPTION: 'WixCode_Remove_Page_Router_Text',
      CANCEL_BUTTON: 'WixCode_Remove_Page_Router_CancelButton',
      CONFIRM_BUTTON: 'WixCode_Remove_Page_Router_DeleteButton',
      TITLE: 'WixCode_Remove_Page_Router_Title'
    }
  }
}
