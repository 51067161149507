'use strict'; // eslint-disable-line strict

const DefaultConsentPolicy = {
  functional: true,
  analytics: true,
  __default: true,
};

const getPolicy = consentPolicyGetter => {
  return (
    (typeof consentPolicyGetter === 'function' && consentPolicyGetter()) ||
    DefaultConsentPolicy
  );
};

const shouldMuteNonEssentials = policy => {
  return policy.functional === false || policy.analytics === false;
};

const shouldMuteByCategory = (policy, category) => {
  if (category === 'essential') {
    return false;
  }

  if (category === 'functional' || category === 'analytics') {
    return policy[category] === false;
  }

  return shouldMuteNonEssentials(policy);
};

module.exports = {
  shouldMuteNonEssentials,
  shouldMuteByCategory,
  getPolicy,
};
