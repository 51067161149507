const {
  openDeleteRoutersJsFilePanel: openDeleteRoutersJsFilePanelDef
} = require('../../../panels/panelDefs')

module.exports = ({ editorSDKProxy }) => {
  const dimensions = {
    width: '600',
    height: '246'
  }

  return editorSDKProxy.editor.openModalPanel(
    Object.assign(
      {},
      {
        url: openDeleteRoutersJsFilePanelDef.url,
        shouldHideHeader: true,
        width: dimensions.width,
        height: dimensions.height
      }
    )
  )
}
