'use strict'

require('isomorphic-fetch')

const editorSdkProxyCreator = require('@wix/wix-code-editor-sdk-proxy')
const {
  wrapWithBreadcrumbs
} = require('@wix/wix-code-editor-sdk-proxy/src/wrapWithBreadcrumbs')

const routersManifest = require('./routersManifest')
const deletePanelConsts = require('../../src/custom-router/panels/delete-page/utils/consts')
const consts = require('../custom-router/utils/consts')
const getUpdatePrefixPanelOptions = require('../custom-router/panels/update-prefix/openPanelOptions')
const panelDefs = require('../panels/panelDefs')

const openDeleteRoutersJsFilePanel = require('../custom-router/panels/delete-routers-js/openPanel')
const {
  create: createConsoleMessagesHandler
} = require('./consoleMessagesHandler')
const { TraceType } = require('../logger')

module.exports = ({ i18nInstance, appLogger, experimentsManager }) => {
  let editorSDKProxy
  let sitePublishedListeners = []

  const experimentsManagerReady = experimentsManager.ready()
  const consoleMessagesHandler = createConsoleMessagesHandler()

  let routersWritable = true
  const appApiCreator = () => ({
    async getAllExperiments() {
      await experimentsManagerReady
      return experimentsManager.all()
    },
    areRoutersWritable() {
      return routersWritable
    },
    setRoutersNotWritable() {
      routersWritable = false
    }
  })

  const editorReady = async function(_editorSDK, _appToken) {
    try {
      const wrappedEditorSDK = wrapWithBreadcrumbs({
        editorSDK: _editorSDK,
        onBreadcrumb: bc => appLogger.trace(TraceType.Breadcrumb(bc))
      })
      editorSDKProxy = editorSdkProxyCreator(wrappedEditorSDK, _appToken)
      const userId = await editorSDKProxy.info.getUserId()
      appLogger.init({
        user: {
          id: userId
        }
      })

      const appApi = appApiCreator()
      await editorSDKProxy.editor.setAppAPI(appApi)
    } catch (e) {
      appLogger.error(e)
      throw e
    }
  }

  const getAppManifest = async function() {
    try {
      await i18nInstance.init(await editorSDKProxy.environment.getLocale()) // TODO: should be moved to editorReady
      return {
        routers: routersManifest(i18nInstance.t.bind(i18nInstance))
      }
    } catch (e) {
      appLogger.error(e)
      throw e
    }
  }

  const openDeletePanel = (initialData, panelType) => {
    const dimensions = {
      width: '600',
      height: '330'
    }

    return editorSDKProxy.editor.openModalPanel({
      url: panelDefs.deletePagePanel.url,
      width: dimensions.width,
      height: dimensions.height,
      shouldHideHeader: true,
      initialData: Object.assign({}, initialData, {
        type: panelType
      })
    })
  }

  const onEvent = async ({ eventType, eventPayload }) => {
    appLogger.trace(
      TraceType.Breadcrumb({
        category: 'event',
        message: eventType,
        data: eventPayload
      })
    )

    try {
      const appApi = await editorSDKProxy.editor.getAppAPI()
      switch (eventType) {
        case 'setRoutersNotWritable':
          appApi.setRoutersNotWritable()
          break
        case 'addConsoleMessage':
          consoleMessagesHandler.addMessage(
            editorSDKProxy,
            eventPayload.consoleMessage
          )
          break
        case 'clearConsoleMessages':
          consoleMessagesHandler.clearMessages()
          break
        case 'addDynamicPageClicked':
          editorSDKProxy.editor
            .openModalPanel({
              url: panelDefs.newPagePanel.url,
              width: '744',
              height: appApi.areRoutersWritable() ? '584' : '545',
              shouldHideHeader: true,
              initialData: eventPayload
            })
            .catch(err => appLogger.error(err))
          break
        case 'updateRouterClicked':
          const res = getUpdatePrefixPanelOptions(
            eventPayload.routerRef,
            appApi.areRoutersWritable()
          )
          editorSDKProxy.editor.openModalPanel(res)
          break
        case 'addPageToRouterClicked':
          editorSDKProxy.editor
            .openModalPanel({
              url: panelDefs.connectPagePanel.url,
              width: '744',
              height: '436',
              shouldHideHeader: true,
              initialData: eventPayload
            })
            .catch(err => appLogger.error(err))
          break
        case 'deleteDynamicPageClicked':
          openDeletePanel(eventPayload, deletePanelConsts.PANEL_TYPES.REMOVE)
          break
        case 'disconnectPageFromRouterClicked':
          openDeletePanel(
            eventPayload,
            deletePanelConsts.PANEL_TYPES.DISCONNECT
          )
          break
        case 'removeRouterClicked':
          const { routerRef } = eventPayload
          editorSDKProxy.routers.remove({ routerRef })
          editorSDKProxy.history.add({
            label: consts.UNDO_REDO_LABELS.REMOVE_CUSTOM_ROUTER
          })
          break
        case 'openWebmodulePermissionsClicked':
          editorSDKProxy.editor
            .openModalPanel({
              url: panelDefs.webmodulesPermissionsPanel.url,
              width: '600',
              height: '430',
              initialData: eventPayload,
              shouldHideHeader: true
            })
            .catch(err => appLogger.error(err))
          break
        case 'siteWasPublished':
          sitePublishedListeners.forEach(listener => listener())
          break
        case 'deleteRoutersJs':
          openDeleteRoutersJsFilePanel({ editorSDKProxy }, eventPayload).catch(
            err => appLogger.error(err)
          )
          break
      }
    } catch (e) {
      appLogger.error(e)
      throw e
    }
  }

  const getControllerPresets = () => {
    return Promise.resolve([])
  }

  const exports = {
    async getWixCodeAuthParams() {
      const instance = await editorSDKProxy.info.getAppInstance()
      const { gridAppId } = await editorSDKProxy.vfs.getViewerInfo()
      return { instance, gridAppId }
    },
    async addSitePublishedListener(listener) {
      sitePublishedListeners = [...sitePublishedListeners, listener]
    }
  }

  return { editorReady, getAppManifest, getControllerPresets, onEvent, exports }
}
